import { createGlobalStyle, keyframes } from "styled-components";

export const theme = {
  canvas: {
    primary: "#fff",
    inverted: "#0D1225",
    darkBlue: "#00092F",
  },

  brand: {
    berry: "#ED33B9;",
    elderflower: "#88CE6A",
    mint: "#4FC4F6",
    spearmint: "#57C7BE",
    watermelon: "#F93F6C",
  },

  text: {
    primary: "#1a3a60",
    black: "#000",
    white: "#fff",
    blue: "#071b6c",
    lightBlue: "#071b6c8a",
    darkBlue: "#0d0c1d",
    grey: "#9d9d9d",
    greySecondary: "#aeaeae",
    lightGrey: "#f9f9f9",
    altGrey: "#C0C0C0",
  },

  colors: {
    primary: "#1a3a60",
    black: "#000",
    white: "#fff",
    blue: "#071b6c",
    newBlue: "#370c85",
    darkBlue: "#0d0c1d",
    darkBlueAlt: "#00092F",
    lightBlue: "#071b6c8a",
    grey: "#9d9d9d",
    greyTertiary: "#474747",
    darkGrey: "#212121",
    lightGrey: "#ebebeb",
    darkGreyVariant: "#272e4e",
  },

  fonts: {
    primary: "'Gotham Book', 'Arial'",
    secondary: "'Nimbus San Con D', 'Arial'",
    tertiary: "'Gotham BookItalic', 'Arial'",
  },

  button: {
    black: "#000",
    white: "#FFF",
    darkBlue: "#00092F",
  },

  shadows: {
    primary: "0px 8px 24px rgba(0, 0, 0, 0.16)",
    secondary: "0px 4px 20px rgba(0, 0, 0, 0.07);",
  },

  margin: {
    mobile: "50px auto",
    desktop: "100px auto",
  },

  padding: {
    mobile: "50px 0",
    desktop: "100px 0",
  },

  breakpoints: {
    small: "600px",
    medium: "992px",
    big: "1260px",
  },
};

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
`;

export const loading = keyframes`

    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export const float = keyframes`

    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    8% {
      transform: translate(20px, 20px) rotate(25deg);
    }
    16% {
      transform: translate(40px, 40px) rotate(50deg);
    }
    24% {
      transform: translate(50px, 50px) rotate(75deg);
    }
    32% {
      transform: translate(40px, 60px) rotate(100deg);
    }
    40% {
      transform: translate(20px, 80px) rotate(125deg);
    }
    48% {
      transform: translate(0px, 100px) rotate(150deg);
    }
    56% {
      transform: translate(-20px, 80px) rotate(175deg);
    }
    64% {
      transform: translate(-40px, 60px) rotate(200deg);
    }
    72% {
      transform: translate(-50px, 50px) rotate(225deg);
    }
    80% {
      transform: translate(-40px, 40px) rotate(250deg);
    }
    88% {
      transform: translate(-30px, 30px) rotate(275deg);
    }
    92% {
      transform: translate(-20px, 20px) rotate(300deg);
    }
    96% {
      transform: translate(-10px, 10px) rotate(330deg);
    }
    100% {
      transform: translate(-0px, 0px) rotate(360deg);
    }
`;

export const popRight1 = keyframes`
    6% {
      opacity: 0;
    }
    7% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg) scale(0.5);
    }
    100% {
      transform: translate(150px, 150px) rotate(150deg) scale(1);
    }
`;

export const popRight2 = keyframes`
    4% {
      opacity: 0;
    }
    5% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg) scale(0.7);
    }
    100% {
      transform: translate(200px, -150px) rotate(-90deg) scale(1);

    }
`;

export const popRight3 = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(0.7);
  }
  100% {
    transform: translate(170px, 40px) rotate(170deg) scale(1);
  }
`;

export const popLeft4 = keyframes`
    6% {
      opacity: 0;
    }
    7% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg) scale(0.3);
    }
    100% {
      transform: translate(-130px, -100px) rotate(180deg) scale(1);
    }
`;

export const popLeft5 = keyframes`
    2% {
      opacity: 0;
    }
    3% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg) scale(0.5);
    }
    100% {
      transform: translate(-230px, -50px) rotate(150deg) scale(1);
    }
`;

export const popLeft6 = keyframes`
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg) scale(0.5);
    }
    100% {
      transform: translate(-140px, 130px) rotate(-190deg) scale(1);
    }
`;

/* Styled components */
export const GlobalStyles = createGlobalStyle`


@font-face {
  font-family: "Gotham Book";
  src: url('/fonts/Gotham-Book.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Gotham Medium";
  src: url('/fonts/Gotham-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gotham BookItalic";
  src: url('/fonts/Gotham-BookItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
} 

@font-face {
  font-family: "Nimbus San Con D";
  src: url('/fonts/NimbusSanConD-Bol.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

.h1 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 120%;
  color: ${theme.text.primary};
  margin: 0;
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 60px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 40px;
  }
}

.h2 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 120%;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 40px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 30px;
  }
}

.h3 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 24px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 24px;
  }
}

.h4 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 20px;
  }
}

.h5 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 18px;
  }
}

.h6 {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 14px;
  }
}

.h1-caps {
  font-size: 80px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.text.primary};
  margin: 0;
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 60px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 36px;
  }
}

.h2-caps {
  font-size: 46px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.colors.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 40px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 30px;
  }
}

.h3-caps {
  font-size: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.colors.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 24px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 24px;
  }
}

.h4-caps {
  font-size: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.colors.primary};
  word-break: break-word;
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 18px;
  }
}

.h5-caps {
  font-size: 18px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.colors.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 14px;
  }
}

.h6-caps {
  font-size: 14px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  color: ${theme.colors.primary};
  @media screen and (max-width: ${theme.breakpoints.medium}) {
    font-size: 12px;
  }
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 12px;
  }
}

.bread {
  font-family: 'Gotham Book', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 14px;
  }
}

.bread-big {
  font-family: 'Gotham Book', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 14px;
  }
}

.bread-small {
  font-family: 'Gotham Book', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  color: ${theme.text.primary};
  @media screen and (max-width: ${theme.breakpoints.small}) {
    font-size: 12px;
  }
}

.details-small {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  -webkit-font-smoothing: antialiased;
  transform: translateY(2px);
}

.details-big {
  font-family: 'Nimbus San Con D', 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  -webkit-font-smoothing: antialiased;
  transform: translateY(2px);

}

// Buttons

.primary-button {
  appearance: none;
  background: transparent;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid ${theme.text.primary};
  font-family: "Nimbus San Con D", 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: ${theme.text.primary};
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease;
  &:hover {
    background: ${theme.text.primary};
    color: ${theme.text.white};
    border-color: ${theme.text.primary};
  }
}


.button-large {
  font-size: 14px;
  padding: 24px 39px 21px;
  &:hover {
    color: ${theme.text.white};
  }
}

.button-small {
  font-size: 16px;
  padding: 22px 30px 19px;
}

.button-mini {
  font-size: 12px;
  padding: 18px 24px 15px;
}

.button-minimal {
  font-size: 10px;
  padding: 13px 15px 11px;
}

.button-filled {
  background-color: ${theme.text.primary};
  color: ${theme.text.lightGrey};
  &:hover {
    background: #32547c;
    color: ${theme.colors.white};
  }
}

.button-filled-white {
  background-color: ${theme.text.white};
  color: ${theme.text.primary};
  border-color: ${theme.text.white};
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  &:hover {
    background-color: ${theme.text.primary};
  }
}


* {
  box-sizing: border-box;
}

html {
  max-width: 100vw;
  min-width: 100vw;
  overflow-x: hidden ;
}

body {
  max-width: 100vw;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  }

  a {
    color: ${theme.colors.primary};
  }
  
  .age-emblem {
    margin-top: 4px;
    width: 250px;
    align-self: flex-end;
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      margin-top: 12px;
    }
  }



.main-inner {
  box-sizing: border-box;
  position: relative;

  height: 100%;
  max-width: 1440px;
  
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    // padding: 0 20px;
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
    min-width: 100vw;
    min-height: 100vh;
 
  }
}


// Product pages general styling

.product__order-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 70px;
  min-height: 600px;
}

.product__order-title-and-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  padding: 100px;
}

.product__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  margin-bottom: 40px;
}

.product__img-dosa {
  width: 377px;
  height: 377px;
  margin: 0 auto;
  animation: ${rotate} 50s linear infinite;
  z-index: -1;
}

.product__accordion {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.product__rel-product__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-bottom: -230px;
}

.product__rel-product-title {
  text-align: center;
  margin: 0;
  margin-bottom: -30px;
}
.product__rel-product-title-1 {
  margin-right: 10px;
  padding: 0;
}
.product__rel-product-title-2 {
  font-family: "Nimbus San Con D", 'Arial';
  font-style: italic;
}

.linked-products {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   
  }

.product__rel-product-product-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;

  //scroll to side on mobile
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  margin-top: 50px;

  //hide scrollbar
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

}

.product__spacer__mobile-footer {
  width: 100%;
  height: 400px;
  background-color: ${theme.canvas.darkBlue};
  border-bottom: 1px solid #ffffff24;
}

@media screen and (max-width: ${theme.breakpoints.big}) {
  .product__order-title-and-image {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
  }

  .product__order-container {
    margin-bottom: 100px;
  }

  .product__accordion {
    align-items: center;
    max-width: 636px;
  }



  .product__spacer__mobile-footer {
    height: 320px;
  }
}


// Styling klarna in the thank you page

.checkout__klarna {
  margin: 0 auto;
  max-width: 701px;
  margin-top: -70px;
  margin-bottom: 150px;
  box-shadow: ${theme.shadows.primary};
  @media screen and (max-width: ${theme.breakpoints.small}) {
    margin: 0 20px 150px;
  }
}





`;
